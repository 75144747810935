<style scoped lang="less">
.top-item-row {
  margin: 10px 15px;
  display: flex;
  justify-content: space-between;
}
.item-row {
  margin: 0.5rem 0.5rem 1rem 0;
  width: 20%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
.item-row > span {
  margin: 0 15px 5px 0;
}
.button-panel {
  width: 100%;
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
}
.input_has_unit {
  width: 180px;
}
</style>

<template>
  <CmDialogBorder
    size="horizontal"
    :zIndex="zIndex"
    title="病变编辑"
    v-show="isShow"
    @close="Close"
  >
    <div class="top-item-row">
      <div class="item-row">
        <span>狭窄部位</span>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/CS_input_lesion_site"
          :value.sync="arteriostenosisTableInfo.lesionSite"
        />
      </div>

      <div class="item-row">
        <span>狭窄长度（mm）</span>
        <cqt-input
          :isReadonly="isReadonly"
          class="input_has_unit"
          placeholder="请输入"
          type="text"
          size="mini"
          v-model="arteriostenosisTableInfo.stenosisLength"
        />
      </div>
      <div class="item-row">
        <span>病变分型</span>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/CS_input_lesion_type"
          :value.sync="arteriostenosisTableInfo.lesionType"
        />
      </div>
      <div class="item-row">
        <span>病变类型</span>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/CS_input_type_lesion "
          :value.sync="arteriostenosisTableInfo.typeLesion"
        />
      </div>
      <div class="item-row"></div>
    </div>
    <div class="top-item-row">
      <div class="item-row">
        <span>既往是否有介入治疗史</span>
        <cqt-radio
          :value.sync="arteriostenosisTableInfo.hasInterventionTherapy"
          :isReadonly="isReadonly"
        >
          <el-radio label="是">是</el-radio>
          <el-radio label="否">否</el-radio>
        </cqt-radio>
      </div>
      <div class="item-row">
        <span>既往支架植入史/球囊扩张史</span>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/CS_input_stent_implantation_history"
          :value.sync="arteriostenosisTableInfo.stentImplantationHistory"
        />
      </div>
      <div class="item-row">
        <span>既往支架/球囊类型</span>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/CS_input_bracket_type"
          :value.sync="arteriostenosisTableInfo.bracketType"
        />
      </div>
      <div class="item-row">
        <span>既往支架植入数量</span>
        <cqt-input
          :isReadonly="isReadonly"
          class="input_has_unit"
          placeholder="请输入"
          type="text"
          size="mini"
          unit="枚"
          v-model="arteriostenosisTableInfo.stentImplantationCount"
        />
      </div>

      <div class="item-row"></div>
    </div>
    <div class="top-item-row">
      <!-- <div class="item-row">
        <span>既往球囊扩张次数</span>
        <cqt-input
          :isReadonly="isReadonly"
          class="input_has_unit"
          placeholder="请输入"
          type="text"
          size="mini"
          unit="枚"
          v-model="arteriostenosisTableInfo.balloonDilatation"
        />
      </div> -->
    </div>
    <div class="top-item-row">
      <el-col v-show="!isReadonly" :span="14" title="处理意见">
        <el-divider content-position="left">处理意见</el-divider>
        <el-radio label="择期支架植入" v-model="handlingOpinions">择期支架植入</el-radio>
        <el-radio label="药物治疗" v-model="handlingOpinions">药物治疗</el-radio>
        <el-radio label="其他治疗" v-model="handlingOpinions">其他治疗</el-radio>
        <el-radio label="本次治疗" v-model="handlingOpinions">本次治疗</el-radio>
      </el-col>
      <el-col v-show="isReadonly" :span="14" title="处理意见">
        <el-divider content-position="left">处理意见</el-divider>
        <el-tag type="info" style="margin-left: 20px" v-model="handlingOpinions">
          {{ handlingOpinions }}
        </el-tag>
      </el-col>
      <el-col v-show="!isReadonly" :span="10">
        <el-divider v-if="handlingOpinions === '本次治疗'" content-position="left">
          本次治疗
        </el-divider>
        <current-checkbox-select
          v-if="handlingOpinions === '本次治疗'"
          v-model="currentTreatment"
          :currentTreatment="this.currentTreatment"
        ></current-checkbox-select>
      </el-col>
      <el-col v-show="isReadonly" :span="10">
        <el-divider v-if="handlingOpinions === '本次治疗'" content-position="left">
          本次治疗
        </el-divider>
        <el-tag type="info" style="margin-left: 20px" v-model="currentTreatment">
          {{ currentTreatment }}
        </el-tag>
      </el-col>
    </div>
    <div class="button-panel">
      <el-button
        <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="Save"
        v-show="!isReadonly"
      >
        保存
      </el-button>
      <el-button
        type="danger"
        class="common-button commonBtn"
        icon="el-icon-close"
        v-debounce="Close"
      >
        关闭
      </el-button>
    </div>
  </CmDialogBorder>
</template>

<script>
import CmDialogBorder from '../../../../components/CmDialogBorder.vue'
import { PopupManager } from 'element-ui/lib/utils/popup'
import { mapState } from 'vuex'
import deepClone from '../../../../plugins/clone'
import CurrentCheckboxSelect from './CurrentCheckboxSelect.vue'

// cqt
import CqtInput from '../../../../components/cqt/CqtInput.vue'
import CqtSelect from '../../../../components/cqt/CqtSelect.vue'
import CqtRadio from '../../../../components/cqt/CqtRadio.vue'
export default {
  name: 'NerveArteryStenosisInfoDialog',
  components: {
    CmDialogBorder,
    CurrentCheckboxSelect,

    CqtSelect,
    CqtInput,
    CqtRadio
  },
  props: {
    isReadonly: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      handlingOpinions: '',
      zIndex: PopupManager.nextZIndex(),
      isShow: false,
      currentTreatment: null,
      arteriostenosisTableInfo: {
        lesionSite: null, //病变部位
        stenosisLength: null, //狭窄长度（mm）
        lesionCharacteristics: null, //病变特点
        timiBloodFlow: null, //TIMI血流
        lesionType: null, //病变分型
        typeLesion: null, //病变分型
        hasInterventionTherapy: null, //既往是否有介入治疗史
        stentImplantationHistory: null, //既往支架植入史/球囊扩张史
        bracketType: null, //既往支架/球囊类型
        stentImplantationCount: null, //既往支架植入数量
        // balloonDilatation: null, //既往球囊扩张次数
        handlingOpinion: null //处理意见
      },
      handlingOpinionsInfo: []
    }
  },
  watch: {
    handlingOpinions: {
      handler(val) {
        this.arteriostenosisTableInfo.handlingOpinion = val
      }
    },

    currentTreatment: {
      handler(val) {
        this.arteriostenosisTableInfo.handlingOpinion = '本次治疗'

        if (val && val.length > 0) {
          this.arteriostenosisTableInfo.handlingOpinion = ['本次治疗', ...val.split('|')].join('+')
        }
      }
    }
  },
  created() {},
  methods: {
    Show(bloodVessel) {
      this.arteriostenosisTableInfo = {}
      this.currentTreatment = ''
      if (bloodVessel) this.arteriostenosisTableInfo = deepClone(bloodVessel)

      let sugs = this.arteriostenosisTableInfo.handlingOpinion?.split('+') || []
      this.handlingOpinions = sugs[0] || ''
      if (sugs.length > 0) {
        this.currentTreatment = sugs?.slice(1).join('|')
      }

      this.isShow = true
    },
    Save() {
      if (this.isReadonly) return
      if (
        this.arteriostenosisTableInfo.lesionSite === null ||
        this.arteriostenosisTableInfo.lesionSite === ''
      ) {
        return this.$message.warning('病变部位不能为空')
      }
      var idx = this.arteriostenosisTableInfo.handlingOpinion?.indexOf('++') || -1
      if (idx != -1) {
        var a = this.arteriostenosisTableInfo.handlingOpinion.substr(0, idx + 1)
        var b = this.arteriostenosisTableInfo.handlingOpinion.substr(idx + 2)
        this.arteriostenosisTableInfo.handlingOpinion = a + b
      }

      this.$emit('save', this.arteriostenosisTableInfo)
      this.Close()
    },

    Close() {
      this.isShow = false
      // this.handlingOpinions = ''
    }
  },

  computed: {
    ...mapState({
      currentPatient: (state) => state.currentPatient
    })
  }
}
</script>
