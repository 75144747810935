<template>
  <div class="info-table">
    <div style="display: flex">
      <span class="inner-title">动脉信息{{ isReadonly ? '' : '录入' }}</span>
    </div>
    <div style="margin: 10px 0 0 10px">
      <div v-show="!isReadonly">
        <el-button
          size="mini"
          type="primary"
          class="commonBtn"
          @click="bloodVesselInsert"
          :disabled="signed_status"
        >
          新增
        </el-button>
        <el-button
          size="mini"
          type="warning"
          class="commonBtn"
          @click="bloodVesselModify"
          :disabled="signed_status"
        >
          修改
        </el-button>
        <el-button
          size="mini"
          type="danger"
          class="commonBtn"
          @click="bloodVesselDelete"
          :disabled="signed_status"
        >
          删除
        </el-button>
      </div>

      <el-table
        class="inner-table"
        highlight-current-row
        :data="bloodVesselTableData"
        :header-cell-style="{ padding: 0 }"
        height="200px"
        style="width: 99%"
        :key="tableKey"
        @row-click="_rowClick"
        @row-dblclick="_doubleModify"
        border
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column prop="lesionSite" label="病变部位"></el-table-column>
        <el-table-column prop="stenosisLength" label="狭窄长度（mm）"></el-table-column>
        <el-table-column prop="lesionType" label="病变分型"></el-table-column>
        <el-table-column prop="typeLesion" label="病变类型"></el-table-column>
        <el-table-column
          prop="hasInterventionTherapy"
          label="既往是否有介入治疗史"
        ></el-table-column>
        <el-table-column
          prop="stentImplantationHistory"
          label="既往支架植入史/球囊扩张史"
        ></el-table-column>
        <el-table-column prop="bracketType" label="既往支架/球囊类型"></el-table-column>
        <el-table-column prop="stentImplantationCount" label="既往支架植入数量"></el-table-column>
        <!-- <el-table-column prop="balloonDilatation" label="既往球囊扩张次数"></el-table-column> -->
        <el-table-column prop="handlingOpinion" label="处理意见"></el-table-column>
      </el-table>
      <nerve-artery-stenosis-info-dialog
        ref="nerveArteryStenosisInfoDialog"
        :isReadonly="isReadonly"
        @save="_dialogSave"
      ></nerve-artery-stenosis-info-dialog>
    </div>
    <div v-show="selectedInfo?.handlingOpinion?.includes('本次治疗')" class="surgery-information">
      <el-tabs type="border-card">
        <!-- 支架植入术 -->
        <el-tab-pane
          :key="key_list[1]"
          v-if="
            selectedInfo &&
            selectedInfo.handlingOpinion &&
            handlingOpinionList.includes('本次治疗') &&
            handlingOpinionList.includes('支架植入术')
          "
        >
          <span slot="label">
            <i :class="stentImplantationDetail.id != null ? 'icon-finished' : 'icon-warn'"></i>
            支架植入术
          </span>
          <div class="infoEntry-table-tab">
            <div class="input-form">
              <div class="input-form-item">
                <div class="input-form-item-label">该部位介入是否成功</div>
                <cqt-radio
                  style="margin-left: 15px"
                  :value.sync="stentImplantationDetail.isSucceed"
                  :isReadonly="isReadonly"
                >
                  <el-radio label="是">是</el-radio>
                  <el-radio label="否">否</el-radio>
                </cqt-radio>
              </div>

              <div class="input-form-item">
                <div class="input-form-item-label">治疗后狭窄程度(%)</div>
                <cqt-select
                  style="margin-left: 15px"
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/CS_degree_stenosis_after_treatment"
                  :value.sync="stentImplantationDetail.degreeStenosisAfterTreatment"
                />
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">支架类型</div>
                <cqt-select
                  style="margin-left: 15px"
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/CS_support_type"
                  :value.sync="stentImplantationDetail.supportType"
                />
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">该部位植入支架数量</div>
                <div style="padding-left: 15px">
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="number"
                    size="mini"
                    unit="枚"
                    v-model="stentImplantationDetail.stentImplantationCount"
                  />
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import NerveArteryStenosisInfoDialog from './NerveArteryStenosisInfoDialog.vue'

// cqt
import CqtInput from '../../../../components/cqt/CqtInput.vue'
import CqtSelect from '../../../../components/cqt/CqtSelect.vue'
import CqtRadio from '../../../../components/cqt/CqtRadio.vue'
export default {
  name: 'NerveArteryStenosisInfoEntry',
  components: {
    NerveArteryStenosisInfoDialog,

    CqtSelect,
    CqtInput,
    CqtRadio
  },
  props: {
    signed_status: {
      type: Boolean,
      require: true
    },
    isReadonly: {
      type: Boolean,
      default: () => false
    },
    formId: {
      type: String,
      require: true
    },
    allDetail: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    selectedInfo: {
      handler(val) {
        if (val) {
          this.handlingOpinionList = val.handlingOpinion?.split('+') || []
          this.getAllSuggestions(val)
        }
      },
      immediate: true
    },
    allDetail: {
      handler(val) {
        if (val) {
          this._initInfoValue()
        }
      }
    }
  },
  data() {
    return {
      bloodVesselTableData: [],
      selectedInfo: null,
      handlingOpinionList: [],
      tableKey: null,
      key_list: [1, 2, 3, 4],
      tabs_index: '0',

      stentImplantationDetail: {
        inputId: null, //动脉信息id
        isSucceed: null, //该部位介入是否成功
        degreeStenosisAfterTreatment: null, //治疗后狭窄程度（%）
        stentImplantationCount: null, //该部位植入支架数量
        supportType: null // 支架类型
      }
    }
  },
  created() {},
  methods: {
    // 页码切换
    handleClick(tab) {
      this.tabs_index = tab.index
    },

    async _initInfoValue() {
      let url = `/v1/webconsole/eform/extendfields/${this.formId}/${this.allDetail.carotidStenosisArtery.id}`
      let formData = new FormData()
      formData.append('elementName', 'carotidStenosisArtery')
      this.$api.post(url, formData).then(
        ({ data: res }) => {
          if (res && res.data) {
            // console.log('nerveArteryStenosisInput', res.data)
            this.bloodVesselTableData = res.data
            for (let i = 0; i < this.bloodVesselTableData.length; i++) {
              // 耗材
              const element = this.bloodVesselTableData[i]
              // 处理意见handlingSuggestions表示有二级分类
              if (element.handlingOpinion) {
                this.getAllSuggestions(element, 'init')
              }
            }
          }
        },
        () => {}
      )
    },
    getAllSuggestions(val, type) {
      if (!val.handlingOpinion?.includes('本次治疗')) return
      // 支架植入术
      if (val.handlingOpinion?.includes('支架植入术')) {
        this._getStentImplantationInfo(val, type)
      }
    },

    bloodVesselInsert() {
      this.$refs.nerveArteryStenosisInfoDialog.Show()
    },
    bloodVesselModify() {
      if (!this.selectedInfo) return this.$message.warning('请先选择一条血管信息')
      if (this.selectedInfo) {
        this.$refs.nerveArteryStenosisInfoDialog.Show(this.selectedInfo)
      }
    },
    bloodVesselDelete() {
      if (!this.selectedInfo) return this.$message.warning('请先选择一条血管信息')
      this.$confirm('是否删除该血管信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          if (this.selectedInfo.id) {
            const index = this.bloodVesselTableData.findIndex(
              (item) => item.id === this.selectedInfo.id
            )
            if (index !== -1) {
              this.bloodVesselTableData.splice(index, 1)
            }
          } else {
            const index = this.bloodVesselTableData.findIndex(
              (item) => item.ids === this.selectedInfo.ids
            )
            if (index !== -1) {
              this.bloodVesselTableData.splice(index, 1)
            }
          }
          this.selectedInfo = null
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    _rowClick(row) {
      // if (this.isReadonly) return
      this.selectedInfo = null
      this.selectedInfo = row

      if (row.id) {
        this.key_list = [
          row.id,
          row.id + Math.random(0, 1000),
          row.id + Math.random(0, 1000),
          row.id + Math.random(0, 1000),
          row.id + Math.random(0, 1000),
          row.id + Math.random(0, 1000),
          row.id + Math.random(0, 1000)
        ]
      } else {
        this.key_list = [
          row.ids,
          row.ids + Math.random(0, 1000),
          row.ids + Math.random(0, 1000),
          row.ids + Math.random(0, 1000),
          row.ids + Math.random(0, 1000),
          row.ids + Math.random(0, 1000),
          row.ids + Math.random(0, 1000)
        ]
      }
    },
    _doubleModify(row) {
      this.selectedInfo = null
      this.selectedInfo = row
      this.$refs.nerveArteryStenosisInfoDialog.Show(this.selectedInfo)
    },
    _dialogSave(val) {
      this.selectedInfo = null
      if (!val.id) {
        let isId = true
        for (let i = 0; i < this.bloodVesselTableData.length; i++) {
          const element = this.bloodVesselTableData[i]
          if (val.ids && val.ids === element.ids) {
            isId = false
            this.bloodVesselTableData[i] = val
            this.$set(this.bloodVesselTableData, i, this.bloodVesselTableData[i])
            break
          }
        }
        if (isId) {
          val.ids = Math.random(0, 1000) + Math.random(0, 1000)
          this.bloodVesselTableData.push(val)
        }
      } else {
        for (let i = 0; i < this.bloodVesselTableData.length; i++) {
          const element = this.bloodVesselTableData[i]
          if (val.id === element.id) {
            this.bloodVesselTableData[i] = val
            this.$set(this.bloodVesselTableData, i, this.bloodVesselTableData[i])
            break
          }
        }
      }
    },

    // 支架植入术
    async _getStentImplantationInfo(val, type) {
      // 轮询赋值
      if (type === 'init') {
        try {
          const url = `/v1/webconsole/eform/extendfields/${this.formId}/${val.id}`
          let formData = new FormData()
          formData.append('elementName', 'carotidStenosisSupport')
          const res = await this.$api.post(url, formData)
          if (res.data && res.data.data && res.data.data.length) {
            val.carotidStenosisSupport = res.data.data[0]
            this.stentImplantationDetail = val.carotidStenosisSupport
          }
        } catch (err) {
          console.log(err)
        }
      } else {
        this.stentImplantationDetail = val.carotidStenosisSupport || {
          ...this.$options.data().stentImplantationDetail
        }

        val.carotidStenosisSupport = this.stentImplantationDetail
      }
    }
  }
}
</script>

<style scoped lang="less">
.infoEntry-table-tab {
  display: flex;
  width: 99%;
  padding: 15px 0;
}
.info-table {
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 20px 15px;
  border-radius: 5px;
  position: relative;
  background-color: #fff !important;
  box-shadow: 0 0.125rem 0.75rem 0 rgba(0, 0, 0, 0.1);
  /deep/.el-table .cell {
    white-space: normal;
  }
  /deep/.top-item-row .item-row span {
    margin: 0 0 0 5px;
  }
  button {
    margin-right: 0.5rem;
  }
}
.inner-title {
  position: absolute;
  top: -12px;
  left: 12px;
  background: #fff;
  padding: 0 10px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  padding-top: 5px;
}
.inner-table {
  margin-top: 10px;
  border: 1px solid black;
  height: 200px;
  position: relative;
}
.inner-tabs {
  margin: 10px 10px;
  height: 250px;
}
.item-inline {
  margin: 15px 25px;
  display: flex;
  justify-content: flex-end;
}
.item-inline > span {
  height: 25px;
  line-height: 25px;
  margin-right: 10px;
}
.item-column {
  display: flex;
  flex-direction: column;
}
.button-panel {
  width: 100%;
  bottom: 0;
  margin: 20px 0 20px 0;
  display: flex;
  justify-content: space-around;
}
.icon-finished::before {
  content: url('../../../../assets/img/finished.png');
}
.icon-warn::before {
  content: url('../../../../assets/img/warn.png');
}

.surgery-information {
  // border-style: solid;
  //     border-width: medium;
  //     border-color: #E4E7ED;
  margin-top: 30px;
  margin-bottom: 20px;

  .input-form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .input-form-item-row {
    width: 100%;
    margin: 0 0.5rem;
    display: flex;
    justify-content: flex-start;
  }

  .input-form-item {
    min-width: 30%;
    margin: 0.4rem 1.5rem;
    display: flex;
    align-items: center;
    .input_has_unit {
      margin: 0;
    }
  }
  @media screen and (max-width: 1180px) {
    .input-form-item {
      min-width: 45%;
      margin: 0.4rem 1rem;
    }
  }

  .input-form-item-label {
    width: 140px;
  }
}
</style>
